import React, { useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  Flex,
  OptGroup,
  Option,
  Select,
  toast,
  Text,
  Tooltip,
  Icon,
} from "@appsmith/ads";
import type { ApiResponseError } from "api/types";
import type {
  GroupedDocuments,
  RagDocument,
  RagDocumentsSelectorProps,
} from "./types";
import {
  fetchAllAndSelectedDocuments,
  updateSelectedDocuments,
} from "./RagApiRequests";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedRagDocumentsInit,
  updateSelectedRagDocumentsSuccess,
} from "ee/actions/ragDocumentsActions";
import { RagAddSources } from "./RagAddSources";
import { RagSearchAllDocs } from "./RagSearchAllDocs";
import { useContainsSelectedDocuments } from "./useContainsSelectedDocuments";
import { useSearchAll } from "./useSearchAll";

export const RagDocumentsSelector = (props: RagDocumentsSelectorProps) => {
  const dispatch = useDispatch();
  const { actionId, datasourceId, formName, workspaceId } = props;
  // We start with loading true because fetchDocuments is called on mount
  // We want to avoid setting this true later on because the handleUpdateFormContainsDocuments
  // depends on this state to determine if the documents are still loading
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState<GroupedDocuments>(
    {} as GroupedDocuments,
  );
  const [documents, setDocuments] = useState<GroupedDocuments>(
    {} as GroupedDocuments,
  );

  const [isSearchAll, setIsSearchAll] = useSearchAll(formName);
  const [isContainsSelectedDocuments, setIsContainsSelectedDocuments] =
    useContainsSelectedDocuments(formName);

  // We want to update the form to indicate if the documents are selected or not
  // if searchAll is true, we want to update the form to indicate if the documents *exist* or not
  // if searchAll is false, we want to update the form to indicate if the documents are *selected* or not
  useEffect(
    function handleUpdateFormContainsDocuments() {
      // If the documents are still loading, don't update the form
      if (isLoading) return;

      if (isSearchAll) {
        const hasDocuments = Object.values(documents).some(
          (docs) => docs.length > 0,
        );

        setIsContainsSelectedDocuments(hasDocuments);
      } else {
        const hasSelectedDocuments = Object.values(selectedDocuments).some(
          (docs) => docs.length > 0,
        );

        setIsContainsSelectedDocuments(hasSelectedDocuments);
      }
    },
    [
      documents,
      isContainsSelectedDocuments,
      isLoading,
      isSearchAll,
      selectedDocuments,
      setIsContainsSelectedDocuments,
    ],
  );

  const ragDocuments = useSelector(
    // Not using AppState as it is causing circular deps
    (state: { ai: { ragDocuments: Record<string, RagDocument[]> } }) =>
      datasourceId ? state.ai.ragDocuments?.[datasourceId] : undefined,
  );

  const onFetchDocuments = useCallback(
    async (datasourceId: string, workspaceId: string, actionId: string) => {
      try {
        const response = await fetchAllAndSelectedDocuments(
          datasourceId,
          workspaceId,
          actionId,
        );

        setDocuments(response.documents);
        setSelectedDocuments(response.selectedDocuments);
      } catch (error) {
        toast.show((error as ApiResponseError).message, { kind: "error" });
      }

      setIsLoading(false);
    },
    [],
  );

  const ragDocumentsIDs = ragDocuments?.map((doc) => doc.ragId).join(",");

  useEffect(
    function handleFetchDocuments() {
      if (datasourceId && workspaceId && actionId) {
        onFetchDocuments(datasourceId, workspaceId, actionId);
      }
    },
    [ragDocumentsIDs, datasourceId, workspaceId, actionId, onFetchDocuments],
  );

  function onSelectedValuesChange(selectedValues: string[]) {
    if (datasourceId && workspaceId && actionId) {
      const allDocuments = Object.entries(documents).flatMap(([type, docs]) =>
        docs.map((doc) => ({
          id: doc.id,
          integrationType: type,
        })),
      );
      const selectedDocs = Object.entries(selectedDocuments).flatMap(
        ([type, docs]) =>
          docs.map((doc) => ({
            id: doc.id,
            integrationType: type,
          })),
      );
      const addedDocuments = allDocuments.filter(
        (doc) =>
          selectedValues.includes(doc.id) &&
          !selectedDocs.some((selectedDoc) => selectedDoc.id === doc.id),
      );
      const removedDocuments = selectedDocs.filter(
        (doc) => !selectedValues.includes(doc.id),
      );

      const newSelectedDocuments: GroupedDocuments = {} as GroupedDocuments;

      selectedValues.forEach((id) => {
        for (const [type, docs] of Object.entries(documents) as [
          keyof GroupedDocuments,
          GroupedDocuments[keyof GroupedDocuments],
        ][]) {
          const doc = docs.find((doc) => doc.id === id);

          if (doc) {
            if (!newSelectedDocuments[type]) {
              newSelectedDocuments[type] = [];
            }

            newSelectedDocuments[type].push(doc);
          }
        }
      });

      setSelectedDocuments(newSelectedDocuments);

      dispatch(updateSelectedRagDocumentsInit());

      updateSelectedDocuments(
        datasourceId,
        workspaceId,
        actionId,
        addedDocuments,
        removedDocuments,
      )
        .catch((error) => {
          toast.show((error as ApiResponseError).message, { kind: "error" });
        })
        .finally(() => {
          dispatch(updateSelectedRagDocumentsSuccess());
        });
    }
  }

  const selectedValues = Object.entries(selectedDocuments).flatMap(([, docs]) =>
    docs.map((doc) => doc.id),
  );

  return (
    <Flex flexDirection="column" gap="spaces-2" mt="spaces-1">
      <Tooltip
        align={{
          points: ["tl", "bl"],
        }}
        content="Knowledge sources provide the agent with information from uploaded files. Once uploaded, the agent automatically decides when to use this content based on user queries."
      >
        <Flex alignItems="center" flexDirection="row" gap="spaces-2">
          <Text isBold kind="heading-s" renderAs="p">
            Knowledge sources
          </Text>
          <Icon name="info" size="md" />
        </Flex>
      </Tooltip>
      <Flex
        alignItems="center"
        flexDirection="row"
        gap="spaces-4"
        justifyContent="space-between"
      >
        <RagSearchAllDocs
          isSearchAll={isSearchAll}
          setIsSearchAll={setIsSearchAll}
        />
        <RagAddSources datasourceId={datasourceId} />
      </Flex>
      {isSearchAll ? null : (
        <Flex flexDirection="row" mt="spaces-4">
          <Select
            isLoading={isLoading}
            isMultiSelect
            onDeselect={(value) => {
              onSelectedValuesChange(
                selectedValues.filter((opt) => opt !== value),
              );
            }}
            onSelect={(value) => {
              onSelectedValuesChange([...selectedValues, value]);
            }}
            optionFilterProp="label"
            optionLabelProp="label"
            placeholder="Select documents"
            showSearch
            value={selectedValues}
            virtual
          >
            {Object.entries(documents).map(([type, docs]) => {
              return (
                <OptGroup key={type} label={type}>
                  {docs.map((doc) => (
                    <Option key={doc.id} label={doc.name} value={doc.id}>
                      <Checkbox isSelected={selectedValues.includes(doc.id)}>
                        {doc.name}
                      </Checkbox>
                    </Option>
                  ))}
                </OptGroup>
              );
            })}
          </Select>
        </Flex>
      )}
    </Flex>
  );
};
